<!-- eslint-disable vue/no-v-html -->
<template>
  <div id="SUMMARYREPORT" class="">
    <div v-if="pageReady" class="content mb-3">
      <div ref="content">
        <h1 class="text-primary fs-1 mb-5 text-center">Assessment Summary</h1>
        <div>
          <!-- history -->
          <div class="p-5 my-5 bg-white shadow">
            <h4 class="fs-1 mb-5 fw-normal">History</h4>

            <p><strong>Educational and occupational history</strong></p>
            <div class="text-gray-600">
              <p
                v-if="
                  report.patient.occupation_status && report.patient.occupation
                "
              >
                <span class="text-capitalize">
                  {{
                    patientGender === 'he'
                      ? 'Mr'
                      : patientGender === 'she'
                      ? 'Miss'
                      : ''
                  }}
                  {{
                    report.patient.patient_details
                      ? [
                          report.patient.patient_details.first_name,
                          report.patient.patient_details.middle_name,
                          report.patient.patient_details.last_name,
                        ].join(' ')
                      : patientGender
                  }}
                </span>
                was employed as a {{ report.patient.occupation_status }}
                {{ report.patient.occupation.toLowerCase() }} with
                {{ report.patient.employer_name }} when the injury occurred.
                <br />

                At the time of the assessment the patient was
                {{
                  report.patient.current_employment_status === 'W'
                    ? 'working'
                    : report.patient.current_employment_status === 'NW'
                    ? 'not working'
                    : ''
                }}.
                <br />
                <!-- if patient not working do not display workhours -->
                <span v-if="report.patient.current_employment_status === 'W'">
                  <span class="text-capitalize">{{ patientGender }}</span>
                  worked
                  {{ report.patient.work_hours }} hours per week.
                </span>
              </p>
              <p v-else>
                Patient did not provide their occupational history information
                at the time of the assessment.
              </p>
            </div>

            <hr class="my-5" />

            <p><strong>Social and personal history</strong></p>
            <div class="text-gray-600">
              <p v-if="report.patient.social_status">
                <span class="text-capitalize">
                  {{
                    report.patient.patient_details
                      ? report.patient.patient_details.name
                      : patientGender
                  }}
                </span>
                informed me that {{ patientGender }} was
                {{ report.patient.social_status }} at the time of the
                assessment.
              </p>
              <p v-else>
                Patient did not provide their social and personal history
                information at the time of the assessment.
              </p>
            </div>

            <hr class="my-5" />

            <p><strong>Past medical history</strong></p>
            <p class="mb-5 text-gray-600">
              <span class="text-capitalize">
                {{
                  report.patient.patient_details
                    ? [
                        report.patient.patient_details.first_name,
                        report.patient.patient_details.middle_name,
                        report.patient.patient_details.last_name,
                      ].join(' ')
                    : patientGender
                }}
              </span>
              report that {{ patientGender }} has
              {{
                report.patient.past_history.length > 0
                  ? 'medical history.'
                  : 'no medical history in the past.'
              }}
            </p>

            <div>
              <p class="text-decoration-underline">
                History of present medical condition(s)
              </p>
              <p>I note that your referral had the following history:</p>
              <!-- this section is for referral request -->
              <div
                v-if="report.referral.history"
                class="fst-italic text-gray-600"
              >
                <div v-html="report.referral.history"></div>
              </div>
              <div v-else>
                <p class="fw-light text-gray-600">
                  There was no referral history provided.
                </p>
              </div>

              <!-- this section is for injury_treatment_histories -->
              <!-- patient medical history or records -->
              <div v-if="report.patient.injury_treatment_histories.length > 0">
                <p>
                  {{
                    report.patient.patient_details
                      ? [
                          report.patient.patient_details.first_name,
                          report.patient.patient_details.middle_name,
                          report.patient.patient_details.last_name,
                        ].join(' ')
                      : patientGender
                  }}
                  confirmed the above history and provided the following
                  additional history:
                </p>
                <div
                  v-for="(info, idx) in report.patient
                    .injury_treatment_histories"
                  :key="idx"
                  class="text-gray-600"
                >
                  <div class="pb-3">
                    <p class="text-decoration-underline fst-italic">
                      {{ idx + 1 }}.
                      {{ info.injury_date ? info.injury_date + ' - ' : '' }}
                      {{ info.treatment_referrel ?? ' ' }}
                    </p>

                    <p>
                      <span class="text-capitalize">
                        {{ patientGender }} was
                      </span>
                      {{ info.injury_cause }}. Patient at the time was
                      experiencing {{ info.symptoms }} .
                      <span class="text-capitalize">
                        {{ patientGender }}
                      </span>
                      sought medical attention from
                      {{ info.initial_treatment_person_name }}, specialist of
                      {{ info.specialist_treatment }} , who arranged the
                      following since then:
                    </p>
                    <p>
                      {{ info.tests }}
                    </p>
                    <p>
                      <span class="text-capitalize">
                        {{ patientGender }}
                      </span>
                      then experienced {{ info.later_symptoms }}.
                    </p>
                  </div>

                  <hr class="my-5" />
                </div>
              </div>
              <div v-else class="my-3">
                The patient did not provide treatments and injuries history
                during time of assesment.
              </div>

              <hr class="my-5" />

              <!-- symptoms -->
              <div class="my-5">
                <p><strong>Current symptoms</strong></p>

                <!-- •	Constant 6-9 out of 10 lumbar spine pain worsen with vacuuming, mopping and gardening. She avoided these tasks and her partner had been helping her. She is able to bend down to change shoes and socks -->
                <div
                  v-if="
                    !report.patient.symptoms.every((x) => x.isEmpty === true)
                  "
                >
                  <p class="pb-4">
                    At the time of the assessment,
                    {{
                      report.patient.patient_details
                        ? [
                            report.patient.patient_details.first_name,
                            report.patient.patient_details.middle_name,
                            report.patient.patient_details.last_name,
                          ].join(' ')
                        : patientGender
                    }}
                    reported the ongoing symptoms:
                  </p>
                  <ul
                    v-for="(symptom, index) in report.patient.symptoms"
                    :key="index"
                    class="text-gray-600"
                  >
                    <li v-if="!symptom.isEmpty">
                      <span class="text-capitalize d-inline">
                        {{
                          symptom.body_part
                            ? symptom.body_part
                            : symptom.location
                            ? symptom.location
                            : 'Patient did not provide specific location of the symptom'
                        }}
                        ,
                      </span>
                      <div
                        v-if="
                          symptom.min_pain_score && symptom.max_pain_score !== 0
                        "
                        class="d-inline mb-3"
                      >
                        <p class="d-inline">
                          <span class="text-capitalize">
                            {{
                              symptom.pain_frequency === 'C'
                                ? 'Constant ,'
                                : symptom.pain_frequency === 'I'
                                ? 'Intermittent ,'
                                : ''
                            }}
                          </span>

                          <span v-if="symptom.pain_frequency === 'I'">
                            {{ symptom.intermittent_frequency.duration_one }}
                            {{ symptom.intermittent_frequency.duration_two }}
                            ,
                            {{ symptom.intermittent_comments }}
                          </span>
                          {{ 'pain of ' + symptom.min_pain_score }} -
                          {{ symptom.max_pain_score }} at
                          {{ symptom.location }} and most of the time
                          {{ symptom.majority_pain_score }} out of 10 in
                          severity.
                        </p>
                      </div>
                      <div v-else class="d-inline">
                        Patient did not provide information regarding their pain
                        frequency.
                      </div>
                      <span v-if="symptom.aggravating_factors.length > 0">
                        Pain was worsened with
                        {{
                          formatLongConjunction(symptom.aggravating_factors)
                        }}.
                      </span>
                      <span v-else>
                        The patient did not report of any aggravating factor or
                        loss of activities of living.
                      </span>
                    </li>
                  </ul>
                </div>

                <div v-else class="text-gray-600">
                  <p>
                    The patient did not report any ongoing symptoms. The patient
                    did not report of any aggravating factor or loss of
                    activities of living.
                  </p>
                </div>
              </div>

              <hr class="my-5" />

              <!-- ongoing treatments -->

              <div>
                <p><strong>Current treatments</strong></p>
                <div
                  v-if="report.patient.current_treatment"
                  class="text-gray-600"
                >
                  <ul>
                    <li>{{ report.patient.current_treatment }}</li>
                  </ul>
                </div>

                <div v-else class="text-gray-600">
                  Patient has no ongoing treatments.
                </div>
              </div>

              <hr class="my-5" />

              <!-- ongoing medications -->

              <div>
                <p><strong>Current medications</strong></p>
                <div
                  v-if="report.patient.current_medication"
                  class="text-gray-600"
                >
                  <ul>
                    <li>{{ report.patient.current_medication }}</li>
                  </ul>
                </div>
                <div v-else class="text-gray-600">
                  Patient has no ongoing medications.
                </div>
              </div>

              <hr class="my-5" />
            </div>

            <p><strong>Relevant Imagings</strong></p>
            <div
              v-if="report.patient.consultation.has_relevant_radiology_images"
              class="text-gray-600"
            >
              <p>
                Patient has provided relevant radiology imagings for the
                relevant assessments
              </p>
            </div>

            <p v-else class="text-gray-600">Not applicable.</p>

            <hr class="my-5" />

            <div v-if="report.consultation">
              <p><strong>History of any compensable events</strong></p>
              <p
                v-if="report.consultation.compensable_history"
                class="text-gray-600"
              >
                {{ report.consultation.compensable_history }}
              </p>
              <p v-else class="text-gray-600">
                Patient did not report of any compensable events.
              </p>

              <hr class="my-5" />

              <p>
                <strong>Any pre-existing injuries or conditions</strong>
              </p>
              <p
                v-if="report.consultation.has_preinjury_history"
                class="text-gray-600"
              >
                {{
                  report.consultation.preinjury_history
                    ? report.consultation.preinjury_history
                    : 'Patient had pre-existing injuries or conditions, but was unable to provide more information.'
                }}
              </p>
              <p v-else class="text-gray-600">
                The patient did not have any other significantly relevant prior
                injury.
              </p>

              <hr class="my-5" />
            </div>

            <div v-else class="text-gray-600">
              <p>
                There was no history of compensable events or pre-existing
                injuries or conditions recorded
              </p>
            </div>
          </div>
          <div class="p-5 my-5 bg-white shadow">
            <!-- examination -->
            <div>
              <h4 class="fs-1 mb-5 fw-normal">Examination</h4>

              <span class="text-gray-600">
                Movements, unless indicated otherwise, were measured in the
                active range and on the best out of 3 attempts. The examination
                was performed as per the AMA5 requirements.
              </span>
            </div>

            <div
              v-if="assessedParts.length > 0"
              class="mx-auto col-12 pt-3 my-3"
            >
              <div v-for="item in assessedParts" :key="item" class="my-5">
                <div v-if="item.spine_name">
                  <SpineReportTable
                    :table-data="{
                      spineData: {
                        muscles: item.muscles,
                        ...item.radiculopathy,
                      },
                      tableRows: item.impairment,
                      tableHead: ['Motion', ''],

                      patientGender: patientGender,
                      spineName: item.spine_name,
                    }"
                  />
                </div>
                <div v-else>
                  <FingersReportTable
                    v-if="
                      item.title.includes('finger') ||
                      item.title.includes('thumb')
                    "
                    :table-data="item"
                  />

                  <DefaultReportTable v-else :table-data="item" />
                </div>
              </div>
            </div>
          </div>

          <br />

          <!-- ratable impairments -->
          <div class="p-5 my-5 bg-white shadow">
            <h4 class="fs-1 mb-5">Rateable Impairment</h4>

            <p class="mt-4">
              Impairment assessment for each work injury listed:
            </p>

            <div class="mx-auto my-5">
              <div
                v-for="item in assessedParts"
                :key="item"
                class="my-5 table-responsive"
              >
                <div v-if="item.spine_name">
                  <p class="text-capitalize text-decoration-underline fw-bold">
                    {{ item.spine_name }}
                  </p>
                  <SpineOutputs
                    v-model:wpi-output="item['wpi']"
                    :gender="patientGender ? patientGender : 'patient'"
                    :data="item"
                  />
                </div>
                <div v-else>
                  <p class="text-capitalize text-decoration-underline fw-bold">
                    {{ item.title }}
                  </p>
                  <p>
                    The range of motion loss is assessed as per
                    {{ item.ama }} of AMA5, which allocate the following
                    impairment:
                  </p>
                  <FingersImpairmentTable
                    v-if="
                      item.title.includes('finger') ||
                      item.title.includes('thumb')
                    "
                    :table-data="item"
                  />
                  <DefaultImpairmentTable v-else :table-data="item" />
                </div>
              </div>
            </div>
          </div>

          <div class="p-5 my-5 bg-white shadow">
            <!-- summary table -->
            <h4 class="fs-1 mb-5">Summary Table</h4>
            <div v-if="wpiTable" class="w-100 table-responsive mx-auto">
              <table
                ref="wpiTable"
                class="summary-table table text-center table-bordered border-dark align-middle"
              >
                <thead>
                  <tr>
                    <th class="sum-header">Body part or system</th>
                    <th class="sum-header">
                      Impairment Assessment Guidelines (Chapter, page,
                      table/figure)
                    </th>
                    <th class="sum-header">
                      AMA5 (Chapter, page, table/figure)
                    </th>
                    <th class="sum-header">% WPI All assesses impairments</th>
                    <th class="sum-header">% WPI Pre-existing impairments</th>
                    <th class="sum-header">% WPI Work injury impairment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(part, index) in wpiTable" :key="index">
                    <td class="text-start text-capitalize">
                      {{ part.bodyPart }}
                    </td>
                    <td>{{ part.guideline }}</td>
                    <td>{{ part.ama }}</td>
                    <td>{{ part.wpi }}</td>
                    <td>-</td>
                    <td>{{ part.wpi }}</td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td class="sum-footer" colspan="3">
                      Totals (from Combined Values Chart AMA5)
                    </td>
                    <td class="sum-footer">
                      {{ wpiOutput }}% WPI Total all assessed impairments
                    </td>
                    <td class="sum-footer">-</td>
                    <td class="sum-footer">
                      {{ wpiOutput }}% WPI Total work injury
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div class="report-end my-5 fst-italic my-4 fs-6 fw-light">
              <p>
                Please note that the Total calculation (is not simply added) is
                a mathematical calculation as per page 604 of AMA 5.The
                combination table(s) is(are) as per the referral request
                (Paragraph 1.20 of the Return To Work Scheme Impairment
                Assessment Guidelines, Page 6). If a different format is
                required, please request for an amended report.
              </p>

              <p>
                The contents of this report are true to the best of my knowledge
                and belief. This report has been written in accordance with the
                current edition of the ReturnToWork Impairment Assessment
                Guidelines, the AMA5, the RTWSA report template (effective 1
                November 2017) and the information made available on the
                assessment date above. Should new information be made available,
                a new report should be requested. I have, to my knowledge, not
                withheld any information.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="spinner-border text-primary" role="status">
      <span class="sr-only" />
    </div>
  </div>
</template>

<script>
import DefaultReportTable from '@/components/Report/DefaultReportTable.vue'
import DefaultImpairmentTable from '@/components/Report/DefaultImpairmentTable.vue'
import FingersReportTable from '@/components/Report/FingersReportTable.vue'
import FingersImpairmentTable from '@/components/Report/FingersImpairmentTable.vue'
import SpineOutputs from '@/components/Report/SpineOutput.vue'
import SpineReportTable from '@/components/Report/SpineReportTable.vue'

import { formatLongConjunction, roundToHundred } from '@/utils/generics.js'

import {
  convertHiToUei,
  convertThumbToHi,
  convertFingerIndexMiddleToHi,
  convertFingerRingPinkyToHi,
  getCombinedValue,
  convertUeiToWpi,
} from '@/utils/calculations.js'

export default {
  name: 'AssessmentSummaryReport',
  components: {
    DefaultReportTable,
    DefaultImpairmentTable,
    FingersReportTable,
    FingersImpairmentTable,
    SpineOutputs,
    SpineReportTable,
  },
  props: { assessmentId: { type: Number, default: null } },

  data() {
    return {
      report: {
        patient: null,
        consultation: null,
      },
      spineOutputData: null,
      assessedParts: [],

      partsAry: [],

      wpiAry: [],
      spineWpi: null,
      patientGender: null,
      pageReady: false,
    }
  },

  computed: {
    wpiOutput: function () {
      let wpiAry = this.wpiTable.map((item) => item.wpi)

      return getCombinedValue(wpiAry)
    },

    wpiTable: function () {
      let finalAry = []

      this.assessedParts.forEach((item) => {
        if (item.direction) {
          item.direction.forEach((d) => {
            finalAry.push({
              bodyPart: `${d} ${item.title}`,
              ama: item.ama,
              guideline: item.guideline,
              wpi: item[`${d}_calculation`].wpi,
            })
          })
        } else if (item.wpi) {
          //this is temporary for lumbar spine

          finalAry.push({
            bodyPart: item.spine_name,
            guideline: 'Chapter 4',
            ama: 'Chapter 15 Table 15.3, Page 384',
            wpi: item.wpi.wpi,
          })
        } else {
          finalAry.push({
            bodyPart: `${item.title}`,
            ama: item.ama,
            guideline: item.guideline,
          })
        }
      })

      return finalAry
    },
  },

  async mounted() {
    //import spineData temporary

    this.spineOutputDataAry = this.$store.state.spineData

    await this.loadValueFromAssessmentTable()
  },

  methods: {
    formatLongConjunction(ary) {
      return formatLongConjunction(ary)
    },

    calculateFingerDI(ary) {
      let obj = { dip_total: 0, pip_total: 0, mp_total: 0 }
      ary?.forEach((e) => {
        if (e.field_name.includes('dip')) {
          obj.dip_total += e.impairment_value
        } else if (e.field_name.includes('pip')) {
          obj.pip_total += e.impairment_value
        } else {
          return (obj.mp_total += e.impairment_value)
        }
      })

      // Finalize array for combine value calculations
      let combinedValAry = Object.values(obj)

      let output = getCombinedValue(combinedValAry)

      return roundToHundred(output)
    },

    calculateUEI(ary) {
      let result = null
      ary.forEach((item) => {
        if (item.field_name === 'ac joint excision' && item.value === 'yes') {
          item.impairment_value = 5
        }
        result += item.impairment_value ?? 0
      })

      return result
    },

    checkForNulls(ary) {
      return ary.every((x) => x.impairment_value === null || x.value === '')
    },

    async loadValueFromAssessmentTable() {
      let payload = {}
      payload.id = parseInt(this.$route.params.id)

      await this.$store
        .dispatch(`getAssessment`, payload)
        .then((response) => {
          const data = response.data

          this.partsAry = data.body_parts

          //map consultation, doctor info, patient info, referrals

          this.report.patient = data.patient
          this.report.referral = data.referral
          this.report.consultation = data.patient.consultation

          //inject isEmpty check on all symptoms
          this.report.patient.symptoms.forEach((s) => {
            if (
              !s.pain_frequency &&
              !s.min_pain_score &&
              !s.max_pain_score &&
              s.aggravating_factors.length === 0
            ) {
              s['isEmpty'] = true
            } else s['isEmpty'] = false
          })

          //this is for stage4 ADL for spine ( only update if detected spine inputs)
          //temporary only allow lumbar spine
          if (this.report.patient && this.spineOutputDataAry.length > 0) {
            this.spineOutputDataAry.forEach((spine) => {
              let obj = {
                ...spine,
                symptoms: this.report.patient.symptoms.find((x) => {
                  return x.body_part.toLowerCase() === spine.spine_name
                }),
              }

              this.assessedParts.push(obj)
            })
          }

          if (this.report.patient.patient_details) {
            //fetch patient basic info that was linked to assessment

            this.patientGender =
              this.report.patient.patient_details.gender === 'M' ? 'he' : 'she'
          } else this.patientGender = 'the patient'

          let filteredAry = []

          // Ensure the array is sorted in ascending order by ID ,
          // This is because the backend will always output same element's name side by side before the next
          // Array needs to look like [ a , a , b , b ]

          this.partsAry.sort((a, b) => {
            if (a.id > b.id) return 1
            if (a.id < b.id) return -1
            return 0
          })

          //temporary remove spines from rendering
          let spines = ['spine_lumbar', 'spine_thoracic', 'spine_cervical']
          let newAry = this.partsAry.filter((part) => {
            return !spines.includes(part.name)
          })

          //this reduced parts have same name into single obj
          newAry.reduce((prev, current) => {
            let obj = {
              title: current.display_name.toLowerCase(),
              direction: !current.direction
                ? []
                : current.direction.includes('L')
                ? ['left']
                : ['right'],

              ama: current.citation_ama5,
              guideline: current.citation_guideline,
            }

            if (prev && current && prev.name === current.name) {
              //because the filtered array will always have the last obj as prev , so if we found same name, remove , edit and push again
              filteredAry.pop()

              if (prev.is_selected && current.is_selected) {
                obj.direction = ['right', 'left']
              } else {
                obj.direction = current.is_selected
                  ? [current.direction === 'R' ? 'right' : 'left']
                  : [prev.direction === 'R' ? 'right' : 'left']
              }

              obj[
                `${
                  !prev.direction
                    ? 'fields'
                    : prev.direction.includes('L')
                    ? 'left'
                    : 'right'
                }`
              ] = prev.fields
              obj[
                `${
                  !current.direction
                    ? 'fields'
                    : current.direction.includes('L')
                    ? ['left']
                    : ['right']
                }`
              ] = current.fields

              obj.specific_diagnosis = {}

              obj.specific_diagnosis[
                `${
                  !prev.direction
                    ? 'diagnosis'
                    : prev.direction.includes('L')
                    ? 'left'
                    : 'right'
                }`
              ] = prev.specific_diagnosis
              obj.specific_diagnosis[
                `${
                  !current.direction
                    ? 'diagnosis'
                    : current.direction.includes('L')
                    ? ['left']
                    : ['right']
                }`
              ] = current.specific_diagnosis

              //for uei and wpi calculation to be done by child
              obj[
                `${
                  !prev.direction
                    ? ''
                    : prev.direction.includes('L')
                    ? 'left_'
                    : 'right_'
                }calculation`
              ] = { uei: 0, wpi: 0 }

              obj[
                `${
                  !current.direction
                    ? ''
                    : current.direction.includes('L')
                    ? 'left_'
                    : 'right_'
                }calculation`
              ] = { uei: 0, wpi: 0 }

              filteredAry.push(obj)

              //setting accumulated value to null when found repeated

              return null
            } else {
              obj[
                `${
                  !current.direction
                    ? 'fields'
                    : current.direction.includes('L')
                    ? ['left']
                    : ['right']
                }`
              ] = current.fields

              obj[
                `${
                  !current.direction
                    ? ''
                    : current.direction.includes('L')
                    ? 'left_'
                    : 'right_'
                }calculation`
              ] = { uei: 0, wpi: 0 }

              obj.specific_diagnosis = {}

              obj.specific_diagnosis[
                `${
                  !current.direction
                    ? 'diagnosis'
                    : current.direction.includes('L')
                    ? 'left'
                    : 'right'
                }`
              ] = current.specific_diagnosis

              filteredAry.push(obj)

              //if prev and current is not same, push the prev obj and return current for next comparison check cycle
              return current
            }
          }, null)

          filteredAry.forEach((item) => {
            //create headers based on assessed body part direction
            if (item.direction.length === 2) {
              item.examinationHeader = ['Motion', 'Right', 'Left']
              item.impairmentHeader = [
                '',
                `Right`,
                'Right upper extremity impairment (%) ',
                `Left`,
                'Left upper extremity impairment (%)',
              ]
            } else {
              //we need to check if opposite examination has value or not
              //get the opposite direction
              let opposite = item.direction.includes('left')
                ? 'right'
                : item.direction.includes('right')
                ? 'left'
                : ''

              //check if theres value inside the opposite ( cannot have null )

              if (this.checkForNulls(item[opposite])) {
                item.examinationHeader = [
                  'Motion',
                  `${
                    item.direction.includes('left')
                      ? 'Left'
                      : item.direction.includes('right')
                      ? 'Right'
                      : ''
                  }`,
                ]
              } else {
                item.examinationHeader = ['Motion', 'Right', 'Left']
              }

              item.impairmentHeader = [
                '',
                `${
                  item.direction.includes('left')
                    ? 'Left'
                    : item.direction.includes('right')
                    ? 'Right'
                    : ''
                }`,
                `${
                  item.direction.includes('left')
                    ? 'Left'
                    : item.direction.includes('right')
                    ? 'Right'
                    : ''
                } upper extremity impairment (%) `,
              ]
            }

            //count UEI n WPI
            if (item.direction) {
              //remove underscore in field name for table to detect title using .includes()

              item?.['left'].forEach((x) => {
                return (x.field_name = x.field_name.replace(/_/g, ' '))
              })
              item?.['right'].forEach((x) => {
                return (x.field_name = x.field_name.replace(/_/g, ' '))
              })

              //finger have special ways to calculate HI, UEI, WPI
              if (item.title.includes('thumb')) {
                item.direction.forEach((d) => {
                  //to calculate combined digital impairment for thumb it uses the same method as normal total uei equation
                  item[`${d}_calculation`].di = this.calculateUEI(item[d])

                  //thumb to hand impairment
                  item[`${d}_calculation`].hi = convertThumbToHi(
                    item[`${d}_calculation`].di
                  )

                  //now to calculate hi to uei
                  item[`${d}_calculation`].uei = convertHiToUei(
                    item[`${d}_calculation`].hi
                  )

                  item[`${d}_calculation`].wpi = convertUeiToWpi(
                    item[`${d}_calculation`].uei
                  )
                })
              } else if (item.title.includes('finger')) {
                item.direction.forEach((d) => {
                  if (!Object.keys(item).includes(`${d}_calculation`)) {
                    // Create object if key doesn't exist
                    item[`${d}_calculation`] = {}
                  }
                  //to calculate combined digital impairment for finger only
                  item[`${d}_calculation`].di = this.calculateFingerDI(item[d])

                  //check what position of fingers, as 2-3 and 4-5 has different equation
                  //
                  //this is for index & middle
                  //hi = hand impairment
                  if (
                    item.title.includes('index') ||
                    item.title.includes('middle')
                  ) {
                    item[`${d}_calculation`].hi = convertFingerIndexMiddleToHi(
                      item[`${d}_calculation`].di
                    )
                  } else {
                    item[`${d}_calculation`].hi = convertFingerRingPinkyToHi(
                      item[`${d}_calculation`].di
                    )
                  }

                  //now to calculate hi to uei
                  item[`${d}_calculation`].uei = convertHiToUei(
                    item[`${d}_calculation`].hi
                  )

                  item[`${d}_calculation`].wpi = convertUeiToWpi(
                    item[`${d}_calculation`].uei
                  )
                })
              } else
                item.direction.forEach((d) => {
                  item[`${d}_calculation`].uei = this.calculateUEI(item[d])

                  item[`${d}_calculation`].wpi = convertUeiToWpi(
                    item[`${d}_calculation`].uei
                  )
                })
            } else {
              item['calculation'].uei = this.calculateUEI(item['field'])
              item['calculation'].wpi = convertUeiToWpi(item['calculation'].uei)
            }

            // Final push for impairment/assessment table to render data
            this.assessedParts.push(item)
          })

          this.pageReady = true
        })
        .catch((error) => {
          console.log(error)
          this.pageReady = true
        })
    },
  },
}
</script>
<style lang="scss" scoped>
#SUMMARYREPORT {
  font-family: 'Montserrat' !important;
  font-size: 17px;
  line-height: 110%;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.title {
  margin-bottom: 10px;
}
.part-title {
  margin-top: 20px;
}
.row-items {
  margin-bottom: 10px;
}

.summary-table {
  .sum-header {
    background-color: #de5f5f;

    padding: 20px 8px;
    font-weight: 400;
    vertical-align: middle;
    color: white;
  }
  .sum-footer {
    background-color: rgba(255, 210, 151, 0.199);
  }
}
</style>
